<style lang="less" scoped>
.confirm-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.45);
  .confirm-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .close-area {
      flex: 1;
    }
    .confirm {
      width: 6.44rem;
      background: white;
      border-radius: 0.16rem;
      display: flex;
      flex-direction: column;
      .confirm-footer {
        border-top: 0.02rem solid #eeeeee;
        height: 1.1rem;
        display: flex;
        .confirm-cancel {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.36rem;
          width: 50%;
          border-right: 0.02rem solid #eeeeee;
          color: #888888;
          cursor: pointer;
        } 
        .confirm-confirm {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          font-size: 0.36rem;
          color: #54cea4;
          cursor: pointer;
        }
      }
    }
    .close-area {
      flex: 1;
    }
  }
}
</style>
<template>
  <div class="confirm-mask" v-if="visible">
    <div class="confirm-container">
      <div class="close-area" @click="closeDialog"></div>
      <div class="confirm">
        <slot></slot>
        <div class="confirm-footer">
          <div class="confirm-cancel" @click="cancel">{{cancelText}}</div>
          <div class="confirm-confirm" @click="confirm">{{confirmText}}</div>
        </div>
      </div>
      <div class="close-area"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Confirm',
  props: {
    confirmText: {
      type: String,
      default: '已完成支付'
    },
    visible: {
      type: Boolean,
      default: false
    },
    cancelText: {
      type: String,
      default: '重新支付'
    },
  },
  methods: {
    closeDialog () {
      this.$emit('cancel')
    },
    cancel() {
      this.$emit('cancel')
    },
    confirm() {
      this.$emit('confirm')
    }
  }
}
</script>
