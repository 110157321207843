<template>
  <div id="renewal">
    <div id="invite-bg">
      <img src="../../../assets/activation.png" />
    </div>
    <div class="bg-wrap">
      <div class="desc">
        <div class="main">续费中心</div>
      </div>
      <input
        v-model="email"
        @blur="getRenewalInfo"
        placeholder="请输入需要续费的邮箱"
      />
      <div class="renewal-sku-title">选择续费时长</div>
      <div class="sku-tabs">
        <div
          class="sku"
          @click="skuTap(index)"
          v-for="(sku, index) in skus"
          :class="{
            active: skuActIndex == index,
            discount: sku.discount !== 1
          }"
        >
          <span class="discount" v-if="sku.discount !== 1"
            >- {{ ((1 - sku.discount) * 100).toFixed(0) }}%</span
          >
          {{ sku.name }}
        </div>
      </div>
      <div class="check-code" @click="renewal">立即续费</div>
      <!-- <div class="intro-bg">
        <div class="renewal-intro">
          <div id="title">续费说明</div>
          <div class="intro-text">
            因为任天堂的政策调整，家庭
          </div>
        </div>
      </div> -->
    </div>

    <confirm
      :visible="confirmVisible"
      @cancel="confirmVisible = false"
      :confirmText="'去首页'"
      :cancelText="'重新填写'"
      @confirm="confirm"
    >
      <div class="confirm-body">此邮箱不在车上，无需续费，请到首页去下单吧</div>
    </confirm>
    <Loading :display="showLoading"></Loading>
    <Toast
      :showToast="showToast"
      :message="toastMsg"
      @disappear="showToast = false"
    ></Toast>
  </div>
</template>
<script>
import Toast from '@/components/Toast'
import Loading from '@/components/Loading'
import Confirm from '@/components/Confirm'
import { reportError } from '@/utils/log'
import debounce from '@/utils/debounce'
import { renewal, getRenewalInfo } from '@/api/carpool/renewal'

export default {
  name: 'renewal',
  data () {
    return {
      skuActIndex: 0,
      toastMsg: '',
      showToast: false,
      showLoading: false,
      email: '',
      confirmVisible: false,
      skus: []
    }
  },
  components: {
    Toast,
    Confirm,
    Loading
  },
  methods: {
    async getRenewalInfo () {
      try {
        this.showLoading = true
        let params = {
          uuid: localStorage.getItem('uuid')
        }
        if (this.email) params['email'] = this.email
        let res = await getRenewalInfo(params)
        this.showLoading = false
        if (res.data.code == 0) {
          this.email = res.data.email
          this.skus = res.data.skus
        } else {
          this.toastMsg = res.data.message
          this.showToast = true
        }
      } catch (error) {
        return -1
      }
    },
    confirm () {
      this.$router.push('/')
    },
    skuTap (skuActIndex) {
      this.skuActIndex = skuActIndex
    },
    order () {
      this.$router.push('/order')
    },
    checkMail (email) {
      return /^[0-9a-zA-Z_.+-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/.test(
        email
      )
    },
    renewal: debounce(async function () {
      try {
        this.email = this.email.replace(/\s+/g, '')
        if (!this.checkMail(this.email)) {
          this.toastMsg = '请输入正确的邮箱'
          this.showToast = true
          return
        }
        this.showLoading = true
        let res = await renewal({
          email: this.email,
          uuid: localStorage.getItem('uuid') || '',
          cf: localStorage.getItem('cf') || '',
          skuId: this.skus[this.skuActIndex].id
        })
        this.showLoading = false
        if (res.data.code == 0) {
          this.$router.push(
            `/carpool/renewal/purchase?orderid=${res.data.order.orderId}`
          )
        } else if (res.data.code == 2) {
          this.confirmVisible = true
        } else {
          this.toastMsg = res.data.message
          this.showToast = true
        }
      } catch (error) {
        reportError(error)
      }
    }, 200)
  },
  created () {
    if (this.$route.query.email) {
      this.email = this.$route.query.email
    }
    this.getRenewalInfo()
  }
}
</script>

<style lang="less" scoped>
#renewal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 7.5rem;
  background: #f2f3e9;
  font-family: PingFangSC-Medium, PingFang SC;
  position: relative;
  #invite-bg {
    position: absolute;
    top: 0;
    width: 7.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .bg-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: scroll;
    .desc {
      margin-top: 0.22rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.4rem;
      .main {
        font-size: 0.76rem;
        font-weight: 500;
        color: #339886;
      }
      .sub {
        color: #339886;
        font-size: 0.28rem;
        font-weight: 500;
        margin-top: 0.12rem;
      }
    }
    input {
      width: 5.34rem;
      z-index: 1;
      border: 0.02rem solid #339886;
      height: 0.4rem;
      padding: 0.12rem 0;
      font-size: 0.28rem;
      outline: none;
      border-radius: 0.34rem;
      padding-left: 0.24rem;
      margin-bottom: 0.16rem;
      &::placeholder {
        font-size: 0.28rem;
        color: #c5c6c5;
      }
    }
    .renewal-sku-title {
      width: 5.54rem;
      font-size: 0.28rem;
      font-weight: 400;
      margin-top: 0.12rem;
      color: #9d9d9d;
    }
    .sku-tabs {
      margin-top: 0.16rem;
      width: 5.54rem;
      display: flex;
      font-weight: 500;
      font-size: 0.34rem;
      color: #000102;
      z-index: 1;
      justify-content: space-between;
      flex-shrink: 0;
      .sku {
        flex: 1;
        margin-right: 0.12rem;
        height: 1.2rem;
        background: #f6f7f8;
        box-shadow: 0px 0px 0.08rem 0.04rem rgba(0, 0, 0, 0.05);
        border-radius: 0.08rem;
        border: 0.02rem solid #e7e7e7;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          box-shadow: 0px 0px 0.08rem 0.04rem rgba(83, 204, 168, 0.2);
          background: #ebfff9;
          border: 0.02rem solid #53cca8;
        }
        &.discount {
          position: relative;
          font-size: 0.34rem;
          top: 0;
          right: 0;
          z-index: 0;
          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            border-color: transparent;
            border-style: solid;
          }

          &::before {
            border-width: 0.36rem;
            border-right-color: #f53f3f;
            border-top-color: #f53f3f;
            border-top-right-radius: 0.1rem;
          }

          &::after {
            border-radius: 0.3rem;
            border-width: 0.16rem;
            border-right-color: #f53f3f;
            border-top-color: #f53f3f;
          }
        }
        .discount {
          position: absolute;
          top: 0.04rem;
          color: #fff;
          font-size: 0.16rem;
          right: 0.06rem;
          z-index: 100;
        }
      }
    }
    .check-code {
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(90deg, #53ccaa 0%, #5bd88f 100%);
      box-shadow: 0px 0px 0.08rem 0.04rem rgba(16, 66, 57, 0.18);
      border-radius: 0.14rem;
      font-weight: 500;
      color: #ffffff;
      width: 5.6rem;
      height: 0.9rem;
      font-size: 0.36rem;
      cursor: pointer;
      margin-top: 0.66rem;
    }
    .intro-bg {
      margin-top: 1rem;
      z-index: 1;
      width: 7.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .renewal-intro {
        width: 7.02rem;
        margin-top: 0.36rem;
        background: #fff;
        border-radius: 0.04rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 0.4rem;
        margin-bottom: 0.24rem;
        #title {
          font-size: 0.32rem;
          font-weight: 500;
          color: #6a6a6a;
          margin-top: 0.24rem;
        }
        .intro-text {
          width: 6.54rem;
          display: flex;
          font-size: 0.28rem;
          color: #5c5c5c;
          font-size: 400;
          justify-content: flex-start;
          margin-top: 0.1rem;
        }
        img {
          width: 3.26rem;
          height: 3.26rem;
        }
        .scan-desc {
          font-size: 0.24rem;
          color: #666666;
        }
      }
    }
  }
  .confirm-body {
    height: 2.2rem;
    padding-left: 0.24rem;
    padding-right: 0.24rem;
    font-size: 0.32rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    color: #939393;
  }
}
</style>
