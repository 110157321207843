
<template>
  <div class="loading-mask" v-if="display">
    <div class="containner">
      <div class="loading">
        <img class="loading-gif" src="../assets/loading.gif"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    display: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped lang="less">
  .loading-mask {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100000;
    .containner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.62rem;
        height: 1.62rem;
        border-radius: .20rem;
        background: rgba(0, 0, 0, 0.57);
        .loading-gif {
          height: 1.02rem;
          width: 1.02rem;
        }
      }
    }
  }
</style>