import { _axios } from '@/utils/axios'

export function renewal(data) { // 生成续费订单
  return _axios({
    url: '/carpool/ns/renewal',
    method: 'post',
    data
  })
}

export function getRenewalInfo(params) { // 获取续费的信息
  return _axios({
    url: '/carpool/ns/renewal/info',
    method: 'get',
    params
  })
}